<template>
  <div>
    <v-card class="elevation-0">
      <v-card-title class="newDialogWidth pt-5 pb-3 px-4 blue-grey--text text--darken-2">
        <v-icon>filter_alt</v-icon> Filtros<v-btn class="mt-1 ml-3 pr-5" x-small color="primary" dark
          @click="moreFilters = !moreFilters"><v-icon left dark x-small>
            {{ moreFilters ? "remove" : "add" }}
          </v-icon>
          {{ moreFilters ? "menos" : "mais" }}
        </v-btn>
      </v-card-title>
      <Filters class="newDialogWidth" :profilesCbos="profilesCbos" :banks="banksProfileRegisterCbo"
        :moreFilters="moreFilters" :getBillets="getBillets" />
      <ToolBar class="newDialogWidth" :toogleStatusBatchDialog="toogleStatusBatchDialog"
        :toogleImportBatchDilog="toogleImportBatchDilog" :toogleRegisterBilletDilog="toogleRegisterBilletDilog"
        :selectedsBillets="selectedsBillets" />
      <div class="px-10 newDialogWidth">
        <v-data-table :page="1" :itemsPerPage="perPage" v-model="selectedsBillets" :headers="headers" :items="billets"
          show-select class="newPagination elevation-1 scrollTable thBorderBold">
          <template v-slot:item.dataVencimento="{ item }">
            {{ item.dataVencimento | formatDate }}
          </template>
          <template v-slot:item.payment_date="{ item }">
            <span v-if="item.payment_date">{{ item.payment_date.split(' ')[0] | formatDate }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.status="{ item }">




            <v-menu right v-if="setStatusBillet(item.status)[2].indexOf('Falha') > -1 ? true : false" open-on-hover
              :closeOnContentClick="false">
              <template v-slot:activator="{ on, attrs }">

                <v-icon :color="setStatusBillet(item.status)[0]" :class="setStatusBillet(item.status)[3]" center
                  v-bind="attrs" v-on="on">
                  {{ setStatusBillet(item.status)[1] }}
                </v-icon>

              </template>
              <v-card max-width="380">
                <v-list>
                  <v-list-item>
                    <!-- <v-list-item-avatar>
                        <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John">
                      </v-list-item-avatar> -->
                    <v-list-item-content>
                      <!-- <v-list-item-title>
                        <div class="d-flex align-end">
                          <b>FALHA</b> <v-spacer></v-spacer><small style="margin-bottom: 2px;" class="pr-1">cod -
                          </small><b><span class="deep-orange--text text--darken-3">{{
                            readFali(item.fail.fail_message, 'code')
                              }}</span></b>
                        </div>
                      </v-list-item-title> -->
                      <v-list-item-title>
                        <b>FALHA</b>
                      </v-list-item-title>
                      <v-list-item-subtitle class="mt-2"><small>Código: </small><b><span
                            class="deep-orange--text text--darken-3">{{
                              readFali(item.fail.fail_message, 'code')
                            }}</span></b>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title><small><b>Sacado:</b></small></v-list-item-title>
                      <v-list-item-subtitle class="subtitle-1 primaryNew--text">{{
                        item.data_title.pagador.nome ? item.data_title.pagador.nome :
                          item.data_title.email }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="readFali(item.fail.fail_message, 'ocorrencia') ? true : false">
                    <v-list-item-content>
                      <v-list-item-title><small><b>Ocorrência:</b></small></v-list-item-title>
                      <v-list-item-subtitle class="subtitle-1 primaryNew--text">{{ readFali(item.fail.fail_message,
                        'ocorrencia')
                        }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title><small><b>Mensagem:</b></small></v-list-item-title>
                      <p class="subtitle-1 mb-0 primaryNew--text">{{ readFali(item.fail.fail_message, 'message')
                        }}</p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-card-actions class="text-center">
                  <v-spacer></v-spacer>
                  <v-btn class="" color="primary" text @click="confirmRetryRegisterBillet(item, $event)">
                    Registrar novamente
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-menu>




            <v-tooltip right v-if="setStatusBillet(item.status)[2].indexOf('Falha') < 0 ? true : false">
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="
                  setStatusBillet(item.status)[2].indexOf('Falha') < 0
                    ? null
                    : confirmRetryRegisterBillet(item, $event)
                  " :color="setStatusBillet(item.status)[0]" :class="setStatusBillet(item.status)[3]" center
                  v-bind="attrs" v-on="on">
                  {{ setStatusBillet(item.status)[1] }}
                </v-icon>
              </template>
              <span class="miniTtip" v-html="setStatusBillet(item.status)[2]"></span>
            </v-tooltip>
          </template>
          <template v-slot:item.data_title.pagador.nome="{ item }">
            {{ item.data_title.pagador.nome || "Não identificado" }}
            <div><small>{{ item.data_title.email }}</small></div>
          </template>
          <template v-slot:item.source="{ item }">
            <div class="mt-1">
              <small>Nº boleto: </small><b>{{ item.title_number || "-" }}</b>
            </div>
            <div class="mt-1 mb-1"><small>Code: </small><b>{{ item.transaction_code || "-" }}</b></div>
          </template>
          <template v-slot:item.value="{ item }">
            R$ {{ item.value | formatPrice }}
          </template>
          <template v-slot:item.actions="{ item }">

            <v-menu transition="slide-y-transition" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="primaryNew">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list dense rounded class="">
                <v-subheader class="text-subtitle-2"><b class="primaryNew--text">{{ item.data_title.pagador.nome ?
                  item.data_title.pagador.nome : item.data_title.email
                    }}</b></v-subheader>
                <v-divider class="mb-2"></v-divider>
                <v-list-item @click="editBillet(item, 'detail')">
                  <v-list-item-icon class="mr-4">
                    <v-icon>receipt</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Detalhes do boleto</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="item.status != 'Aberto' ? true : false" @click="openBillet(item)">
                  <v-list-item-icon class="mr-4">
                    <v-icon class="mb-1" :disabled="item.status != 'Aberto' ? true : false">save_alt</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Download do boleto</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="confirmCancelBillet(item)">
                  <v-list-item-icon class="mr-4">
                    <v-icon>do_not_disturb</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Cancelar boleto</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.callback_history.length ? true : false"
                  @click="showCallbackHistory(item.callback_history, item.transaction_code)">
                  <v-list-item-icon class="mr-4">
                    <v-icon>history</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Histórico de callbacks</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:footer.page-text>
            <v-row class="d-flex justify-end">
              <v-col class="pb-0" cols="5">
                <v-container class="d-flex flex-row justify-end max-width pr-0">
                  <v-pagination v-model="actualPage" :length="totalPages" :total-visible="8"></v-pagination>
                </v-container>
              </v-col>
              <v-col style="max-width: 130px;" class="pt-9 px-0 pb-0" cols="2"><span class="caption">Itens por
                  página</span></v-col>
              <v-col style="max-width: 90px;" class="pb-0" cols="2">
                <v-select v-model="perPage" :items="[5, 10, 50, 100]"></v-select>
              </v-col>
            </v-row>
          </template>
        </v-data-table>

      </div>
    </v-card>
    <ListBatchs :listBatchsDialog="listBatchsDialog" :toogleStatusBatchDialog="toogleStatusBatchDialog" />
    <ImportBatchDialog :getBillets="getBillets" :toogleImportBatchDilog="toogleImportBatchDilog"
      :importBatchDialog="importBatchDialog" :profilesCbos="profilesCbos" />
    <RegisterBillet :editDataBillet="editDataBillet" :profilesCbos="profilesCbos" :getBillets="getBillets"
      :toogleRegisterBilletDilog="toogleRegisterBilletDilog" :registerBilletDilog="registerBilletDilog" />
    <CallbackHistoryList :transactionCode="transactionCode" :toogleCallbackHistoryList="toogleCallbackHistoryList"
      :callbacksList="callbacksList" :callbackHistoryDialog="callbackHistoryDialog" />
  </div>
</template>

<script>
import Filters from "./Filters.vue";
import ToolBar from "./ToolBar.vue";
import RegisterBillet from "./RegisterBillet.vue";
import ImportBatchDialog from "./ImportBatchDialog.vue";
import ListBatchs from "./ListBatchs.vue";
import CallbackHistoryList from "./CallbackHistoryList.vue";
import MyFilters from "../../../../filters/genericFilters";
export default {
  destroyed() {
    clearInterval(this.updateStatusBillets);
  },
  watch: {
    perPage(v) {
      // console.log('perpag', v)
      this.getBillets(this.$store.state.lastBilletsParans, false, false, 1, v).then(res => {
        // this.totalPages = res.last_page;
        // this.actualPage = res.current_page;
      });
    },
    actualPage(v, oldV) {
      //console.log("qsqsqs", oldV);
      if (!oldV || !v) {
        return;
      }
      this.getBillets(this.$store.state.lastBilletsParans, false, false, v, this.perPage).then(res => {
        // this.totalPages = res.last_page;
        // this.actualPage = res.current_page;
      });
    },
    billets(v) {
      // console.log('muda lista', v)
      this.selectedsBillets = []
      // if (v) {
      //   this.selectedsBillets = []
      // }
    },
    managerBilletsDialog(v) {
      // console.log('boleto open')
      this.autoUpdateStatusBillet(v);
      if (v) {
      }
    },
  },
  methods: {
    showCallbackHistory(data, code) {
      this.callbacksList = []
      setTimeout(() => {
        this.callbacksList = [...data]
      }, 10);
      this.transactionCode = code
      this.toogleCallbackHistoryList()
    },
    toogleCallbackHistoryList() {
      // this.callbacksList = []
      this.callbackHistoryDialog = !this.callbackHistoryDialog
    },
    readFali(data, item) {
      // console.log('aqui')
      let failInfo = JSON.parse(data)
      return failInfo.Error[item]
    },
    openBillet(data) {
      let obj = {
        message: `O boleto foi criado com sucesso, deseja fazer download?`,
        title: "Salvar boleto",
        callback: (_) => {
          console.log(data.title_url)
          window.open(data.title_url);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    confirmRetryRegisterBillet(data, bt) {
      //console.log('0000',bt.target)
      bt.target.blur();
      let obj = {
        message: `Você deseja registrar novamente o boleto selecionado?`,
        title: "Tentar novamente",
        callback: (_) => {
          this.retryRegisterBillet(data.id);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      // console.log("boleto",data)
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    retryRegisterBillet(id) {
      //console.log(id);
      let obj = {
        url: "/api/v1/title/failOver",
        query: {
          id: id,
        },
        method: "post",
      };

      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        let opts = {
          message: `Nova tentativa de registro enviada com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        this.getBillets(this.$store.state.lastBilletsParans, false).then(res => {
          // this.totalPages = res.last_page;
          // this.actualPage = res.current_page;
        });
      });
    },
    toogleStatusBatchDialog() {
      //console.log("Status Batch");
      this.listBatchsDialog = !this.listBatchsDialog;
    },
    updateBilletsBar(val) {
      val++
      setTimeout(() => {
        this.updateBilletsBar(val)
      }, 1000);
      return val
    },
    autoUpdateStatusBillet() {
      this.updateStatusBillets = setInterval(() => {
        // console.log('autobillet')
        this.getBillets(this.$store.state.lastBilletsParans, true, false, this.actualPage, this.perPage).then(res => {
          // this.totalPages = res.last_page;
          // this.actualPage = res.current_page;
        });
      }, 60000);

    },
    // profilesCbos: Array,
    toogleImportBatchDilog() {
      //console.log("batchj");
      this.importBatchDialog = !this.importBatchDialog;
    },
    toogleRegisterBilletDilog() {
      this.registerBilletDilog = !this.registerBilletDilog;
      this.editDataBillet = null;
    },
    confirmCancelBillet(data) {
      let obj = {
        message: `Você deseja cancelar o boleto selecionado?`,
        title: "Cancelar",
        callback: (_) => {
          this.cancelBillet(data);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      // console.log("boleto",data)
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    cancelBillet(data) {
      let obj = {
        url: "/api/v1/title/amortize",
        query: {
          title_number: data.title_number,
        },
        method: "post",
      };

      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        let opts = {
          message: `Boleto enviado para cancelamento com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        setTimeout(() => {
          document.getElementsByTagName("td")[0].click();
        }, 10);
        this.getBillets(this.$store.state.lastBilletsParans, false).then(res => {
          // this.totalPages = res.last_page;
          // this.actualPage = res.current_page;
        });
        // this.updateBilletLine(data.id, "Baixando/Cancelando");
      });
    },

    setStatusBillet(data) {
      //console.log("fff");
      let status = {
        "Baixado/Cancelado": [
          "grey darken-1",
          "block",
          "Boleto cancelado",
          "justIcon",
        ],
        "Baixando/Cancelando": [
          "grey darken-1",
          "warning",
          "Aguardando cancelamento",
          "justIcon",
        ],
        Andamento: [
          "orange darken-2",
          "query_builder",
          "Aguardando registro",
          "justIcon",
        ],
        Aberto: ["blue darken-1", "verified", "Boleto registrado", "justIcon"],
        Liquidado: [
          "green darken-3",
          "attach_money",
          "Boleto liquidado",
          "justIcon",
        ],
        "Falha no Registro": [
          "red darken-4",
          "error",
          "Falha ao tentar registrar o boleto <br/> Clique aqui para tentar registrar novamente.",
          "iconButton",
        ],
        "Falha na Baixa/Cancelamento": [
          "red darken-4",
          "error",
          "Falha no cancelamento",
          "iconButton",
        ],
        Vencido: ["red lighten-1", "event_busy", "Boleto vencido", "justIcon"],
      };
      return status[data];
    },
    editBillet(data) {
      // console.log("eeee",data)
      this.editDataBillet = { ...data };
      if (!this.editDataBillet.title_liquided) {
        this.editDataBillet.title_liquided = {}
      }
      this.registerBilletDilog = true;
    },
    setHeigtScrollTable() {
      let pag = document.documentElement.clientHeight;
      let sizeTable = pag - 380;
      document.getElementsByClassName(
        "v-data-table__wrapper"
      )[0].style.height = `${sizeTable}px`;
    },
  },
  data() {
    return {
      transactionCode: "",
      callbacksList: [],
      callbackHistoryDialog: false,
      updateStatusBillets: null,
      listBatchsDialog: false,
      importBatchDialog: false,
      editDataBillet: null,
      registerBilletDilog: false,
      moreFilters: false,
      selectedsBillets: [],
      setedPage: false,
      // totalPages: 0,
      perPage: 5,
      actualPage: 0,
      headers: [
        {
          text: "Status",
          value: "status",
          sortable: false,
          sortable: true,
        },
        { text: "Sacado", value: "data_title.pagador.nome", sortable: true },
        { text: "Registro/Transection code", value: "source", sortable: false },
        {
          text: "Vencimento",
          sortable: true,
          value: "dataVencimento",
          align: "center"
        },
        {
          text: "Pagamento",
          value: "payment_date",
          sortable: false,
          align: "center"
        },

        { text: "Valor", value: "value", sortable: false, align: "right" },
        // {
        //   text: "Valor com desconto",
        //   value: "valorAbatimento",
        //   sortable: false,
        // },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
  mounted() {
    this.autoUpdateStatusBillet();
  },
  props: {
    updateBilletLine: Function,
    // verifySteps: Function,
    // stepsOn: Number,
    getBillets: Function,
    billets: Array,
    profilesCbos: Array,
    banksProfileRegisterCbo: Array,
    totalPages: Number,
    // actualPage: Number
  },
  components: {
    Filters,
    ToolBar,
    RegisterBillet,
    ImportBatchDialog,
    ListBatchs,
    CallbackHistoryList
  },
};
</script>

<style>
.statusButton {
  min-width: 24px !important;
}

.iconButton {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.justIcon {
  cursor: auto !important;
}

/* .scrollTable .v-data-table__wrapper {
  overflow-y: auto !important;
} */
.v-btn.v-btn--disabled.btMicro.disableBt {
  background-color: #00796b !important;
  border-color: #00796b !important;
  opacity: 0.4 !important;
}

.newDialogWidth {
  max-width: 1600px !important;
  margin: auto;
}
</style>